import { Plugin } from "@ckeditor/ckeditor5-core";
import { ButtonView } from "@ckeditor/ckeditor5-ui";
import icon from "./icon.svg";

export default class SaveDocument extends Plugin {
    init() {
        //region set translations
        Object.assign(window.CKEDITOR_TRANSLATIONS["es"].dictionary, {
            "Save Document": "Guardar Documento",
        });
        //endregion

        const editor = this.editor;

        editor.ui.componentFactory.add("saveDocument", (locale) => {
            // The button will be an instance of ButtonView.
            const button = new ButtonView(locale);

            const { t } = editor.locale;

            button.set({
                label: t("Save Document"),
                withText: true,
                icon: icon,
            });

            button.extendTemplate({
                attributes: {
                    class: ["btn-hla-cke-plugin"],
                },
            });

            //Execute a callback function when the button is clicked
            button.on("execute", () => {
                if (typeof pluginSaveDocument === "function") {
                    pluginSaveDocument();
                } else {
                    console.error(
                        "The function 'pluginSaveDocument' does not exist."
                    );
                }
            });

            return button;
        });
    }
}
