import { Plugin } from '@ckeditor/ckeditor5-core'
import { ButtonView } from '@ckeditor/ckeditor5-ui'
import icon from './icon.svg'


export default class ExportAsWord extends Plugin {
	init() {
		//region set translations
		Object.assign(window.CKEDITOR_TRANSLATIONS['es'].dictionary, {
			'Export as Word': 'Exportar a Word',
		})
		//endregion

		const editor = this.editor;

		editor.ui.componentFactory.add('exportAsWord', locale => {
			// The button will be an instance of ButtonView.
			const button = new ButtonView(locale)

			const { t } = editor.locale;

			button.set({
				label: t('Export as Word'),
				icon: icon,
				tooltip: true,
			})

			//Execute a callback function when the button is clicked
			button.on('execute', () => {
				if (typeof pluginExportAsWord === 'function') {
                    pluginExportAsWord()
				} else {
					console.error("The function 'pluginExportAsWord' does not exist.")
				}
			})

			return button
		})
	}
}
