import { Plugin } from "@ckeditor/ckeditor5-core";
import { ButtonView } from "@ckeditor/ckeditor5-ui";
import icon from "./icon.svg";

export default class DocumentActions extends Plugin {
    init() {
        //region set translations
        Object.assign(window.CKEDITOR_TRANSLATIONS["es"].dictionary, {
            Paste: "Pegar",
        });
        //endregion

        const editor = this.editor;

        editor.ui.componentFactory.add("paste", (locale) => {
            // The button will be an instance of ButtonView.
            const button = new ButtonView(locale);

            const { t } = editor.locale;

            button.set({
                tooltip: t("Paste"),
                icon: icon,
            });

            //Execute a callback function when the button is clicked
            button.on("execute", async () => {
                try {
                    const text = await navigator.clipboard.readText();
                    const viewFragment = editor.data.processor.toView(text);
                    const modelFragment = editor.data.toModel(viewFragment);
                    editor.model.insertContent(
                        modelFragment,
                        editor.model.document.selection
                    );
                } catch (error) {
                    console.warn(
                        "Clipboard API is only available in secure contexts."
                    );
                    console.error("Failed to paste content.", error);
                }
            });

            return button;
        });
    }
}
